.filmes{
    padding: 50px 0;
    background-color: #fafafa;
}
.filmeWrap{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 90%;
    margin: 0 auto;
}
.filme{
    width: 30%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    margin: 10px;
    padding: 10px;
    color: #000000;
}
.filme h6{
    text-align: center;
    font: 600 20px Roboto,sans-serif;
}
.filme p{
    text-align: center;
    font: normal 16px Roboto,sans-serif;
}